<template>
  <div class="goods-details">
    <mall-head />
    <div class="goods-details-body w1200">
      <div class="goods-introduce">
        <div class="left">
          <el-image :src="goodsDetails.coverImg" />
        </div>
        <div class="right">
          <div class="right-top">
            <div class="title">{{ goodsDetails.details }}</div>
            <div class="price-box">
              <div class="dt">商 场 价</div>
              <div class="dd">
                <span style="color: red">￥ </span>
                <span class="price">{{ goodsDetails.price }}</span>
              </div>
            </div>
            <div class="dt">配 送 至</div>
            <div class="dd">全国</div>
          </div>
          <div class="right-buy">
            <el-input-number
              v-model="goodsNum"
              :min="1"
              :max="200"
              label="商品数量"
            ></el-input-number>
            <div class="add-cart-btn" @click="addCart">加入购物车</div>
          </div>
          <div class="right-tips">
            <div class="tips-left">温馨提示</div>
            <div>· 支持7天无理由退货</div>
          </div>
        </div>
      </div>

      <div class="details-img">
        <div class="tips">详情图</div>
        <hr />
        <div class="details-img-body">
          <el-image
            v-for="(item, index) in goodsDetails.detailsImg"
            :key="index"
            :src="item"
          ></el-image>
        </div>
      </div>
    </div>
    <MallFooter />
  </div>
</template>

<script>
import MallHead from "@/views/MallHead.vue";
import MallFooter from "@/views/MallFooter.vue";
import { goodsData } from "../utils/index";
import storage from "../utils/storage";
export default {
  name: "GoodsDetails",
  components: { MallHead, MallFooter },
  data() {
    return {
      goodsDetails: goodsData[this.$route.params.id],
      goodsNum: 1,
    };
  },
  methods: {
    addCart() {
      const goods = {};
      goods.id = this.goodsDetails.name;
      goods.name = this.goodsDetails.details;
      goods.img = this.goodsDetails.coverImg;
      goods.price = Number(this.goodsDetails.price);
      goods.num = this.goodsNum;
      let buyGoods;
      if (storage.get("buyGoods")) {
        buyGoods = storage.get("buyGoods");
        const goodsId = [];
        buyGoods.forEach((item) => {
          goodsId.push(item.id);
        });
        if (goodsId.indexOf(goods.id) === -1) {
          buyGoods.push(goods);
          storage.set("buyGoods", buyGoods);
        } else {
          buyGoods[goodsId.indexOf(goods.id)].num += goods.num;
          storage.set("buyGoods", buyGoods);
        }
      } else {
        buyGoods = [];
        buyGoods.push(goods);
        storage.set("buyGoods", buyGoods);
      }
      this.$message.success('加入购物车成功')
    },
  },
  created() {
    // storage.remove('buyGoods')
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.goods-details {
  background-color: #f4f4f4;
}
.goods-details-body {
  padding: 20px;
  background-color: #fff;
  margin-top: 1px;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}
.goods-introduce {
  display: flex;
  margin-bottom: 20px;
  .left {
    width: 350px;
    height: 350px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
    flex-shrink: 0;
  }
  .right {
    width: 590px;
    .title {
      font-size: 16px;
      color: #666;
      font-weight: 600;
      padding-top: 10px;
      line-height: 28px;
      margin-bottom: 5px;
    }
    .price-box {
      padding: 8px 0 5px;
      background: #f3f3f3;
      margin-bottom: 5px;
      .price {
        font-size: 22px;
        color: red;
        line-height: 26px;
      }
    }
    .dt {
      float: left;
      padding-left: 10px;
      color: #999;
      line-height: 32px;
    }
    .dd {
      margin-left: 100px;
      line-height: 32px;
    }
    .right-top {
      padding-bottom: 10px;
      border-bottom: 1px dotted #dfdfdf;
    }
    .right-buy {
      display: flex;
      padding-top: 20px;
      .el-input-number {
        width: 150px;
      }
      .add-cart-btn {
        padding: 0 25px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        margin-left: 20px;
        background-color: #df3033;
        color: #fff;
      }
    }
    .right-tips {
      display: flex;
      color: #999;
      font-size: 14px;
      margin-top: 20px;
      .tips-left {
        padding-left: 8px;
        margin-right: 20px;
      }
    }
  }
}
.details-img {
  .tips {
    border-left: 4px solid red;
    padding-left: 8px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .details-img-body {
    padding-top: 20px;
    text-align: center;
    height: 100%;
    .el-image {
      width: 1000px;
    }
  }
}
</style>
